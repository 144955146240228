import { useMsal } from '@azure/msal-react';
import React, { useState, useEffect } from 'react';
import { SilentRequest } from '@azure/msal-browser';
import { decodeToken } from '../Auth/decodeToken';
import { loginRequest } from '../Auth/authConfig';
import MainContent from '../Components/MainContent';
import "../CSS/Login.css";
import logo from "../Images/MilaLogo2Line.png";
import Button from "../Components/LoginButton";
import apiURL from "../API/requests";

interface OrganizationGroup {
    organizationGroupId: string;
    name: string;
    address: string;
    phoneNumber: string;
    milaPhoneNumber: string | null;
    state: string;
}

interface Organization {
    organizationId: string;
    organizationGroupId: string;
    name: string;
    address: string;
    phoneNumber: string;
    milaPhoneNumber: string | null;
    preferredHours: string;
    state: string;
    organizationGroup: OrganizationGroup;
}

interface ProviderController {
    providerId: string;
    organizationId: string;
    name: string;
    specialization: string;
    title: string;
    emailId: string;
    entraId: string;
    state: string;
    organization: Organization;
}

const getEntraCode = async (url: string, token: string): Promise<string> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization': `Bearer ${token}`
            }
        });

        const rawText = await response.text(); // Get the raw response text
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        return rawText; // Return the raw response text
    } catch (error) {
        console.error('Error fetching Entra code:', error);
        throw new Error((error as Error).message); // Throw error to be handled by caller
    }
};

const getProviderController = async (url: string, token: string): Promise<ProviderController> => {
    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: {
                'Accept': '*/*',
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const result: ProviderController = await response.json(); // Parse the response as JSON
        return result;
    } catch (error) {
        console.error('Error fetching provider controller data:', error);
        throw new Error((error as Error).message); // Throw error to be handled by caller
    }
};

const Auth: React.FC = () => {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState<string>('');
    const [userName, setUserName] = useState<string>('');
    const [accessSub, setAccessSub] = useState<string>('');
    const [entraCode, setEntraCode] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [controllerData, setControllerData] = useState<ProviderController | null>(null);
    const [entraId, setEntraId] = useState<string>(''); // Add state for entraId

    useEffect(() => {
        if (inProgress === "none" && accounts.length > 0) {
            handleLogin();
        } else if (inProgress === "none") {
            setLoading(false);
        }
    }, [inProgress, accounts]);

    const handleLogin = async () => {
        setLoading(true);
        try {
            const silentTokenReq: SilentRequest = {
                account: accounts[0],
                scopes: [process.env.REACT_APP_AUTH_SCOPE!, 'openid', 'offline_access'],
                forceRefresh: true,
            };

            const { accessToken } = await instance.acquireTokenSilent(silentTokenReq);
            const { name, sub } = decodeToken(accessToken);

            setAccessToken(accessToken);
            setUserName(name);
            setAccessSub(sub);
            
            const entraCodeUrl = `${apiURL.fetchEntraController}${sub}`;
            const entraCode = await getEntraCode(entraCodeUrl, accessToken);
            setEntraCode(entraCode);

            const providerControllerURL = `${apiURL.fetchProviderController}${entraCode}`;
            const providerController = await getProviderController(providerControllerURL, accessToken);
            setControllerData(providerController);
            const entraID = setEntraId(providerController.organizationId); // Set the entraId
            
            localStorage.setItem("token", accessToken);

            localStorage.setItem("entrID", providerController.organizationId);
            
            // console.log("Token", accessToken);
            // console.log("Controller Data:", providerController);
            // console.log("Entra ID:", providerController.organizationId); // Add logging forentraId
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="headerAndContent">
                <div className="LoginContainer">
                    <div className="loadingFeedback">
                        <div className="spinner" />
                        <div className="loadingMessage">Loading, please wait...</div>
                    </div>
                </div>
            </div>
        );
    }

    if (!userName) {
        return (
            <div className="headerAndContent">
                <div className="LoginContainer">
                    <div className="logoSection">
                        <img className="logo" src={logo} alt="Logo" />
                        <div className="lineDiv" />
                        <div className="slogan">
                            An Engaging & Empathetic AI Assistant
                        </div>
                    </div>
                    <div className="loginCard">
                        <div className="LoginTitle">Provider Login</div>
                        <div style={{ width: '100%' }}>
                            <Button 
                                Label="Connect Azure Authentication" 
                                style="Default"  
                                onClick={() => {
                                    setLoading(true);
                                    instance.loginRedirect(loginRequest);
                                }} 
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return <MainContent user={userName} AccessToken={accessToken} />;
};

export default Auth;
