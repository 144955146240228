import { jwtDecode } from 'jwt-decode';

export type DecodedToken = {
    name: string;
    exp: string;
    sub: string;
};

export const decodeToken = (token: string): DecodedToken => {
    try {
        const decoded: any = jwtDecode(token);
        const exp = new Date(decoded.exp * 1000).toString(); // Convert Unix timestamp to date string
        const name: string = decoded.name; // Assuming 'name' is a field in your JWT payload
        const sub: string = decoded.sub; 
        return { name, exp, sub };
    } catch (err) {
        console.error('Error decoding token:', err);
        return { name: '', exp: '', sub: '' };
    }
};
