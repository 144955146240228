import PersonNote from "../Images/Person Note.png";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import "../CSS/EmptyCard.css";

export const CaseNotSelected = () => {
  return (

      <div className="EmptyCard">
          <div className="Helpbar">
            <div className="help iconSizeInContent">
              <HelpOutlineOutlinedIcon />
            </div>
          </div>
          <img className="PersonNote" src={PersonNote} alt="Logo" />
          <div className="Heading2">
            You have to select the case.
          </div>
          <div className="helper">
            The sidebar section consists of cases with severity. Please select one to know more about the case details. 
          </div>
        </div>

    
  );
}
export default CaseNotSelected;
