import React, { useEffect, useState, useCallback } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import "../CSS/MainContent.css";
import { Header } from "../Components/Header";
import Footer from "./Footer";
import DashCaseDetails from './DashCaseDetails';
import GridDash from './GridDash';
import ListDash from './ListDash';
import AddNewCase from './AddNewCase';
import { useMsal } from '@azure/msal-react';
import apiURL from "../API/requests";
import { Case, Welcome } from './casetypes';
import { OutcomePlanData, WelcomeOP }  from './outcomeplantypes'; 
import Providers from './Providers';
import Organisations from './Organisations';
import EditCase from './EditCase';

type UserProps = {
    user: string;
    AccessToken: string;
}

const MainContent: React.FC<UserProps> = (props) => {
    const { user } = props;
    const [data, setData] = useState<Welcome | null>(null);
    const [draft, setDraft] = useState<Welcome | null>(null);
    const [outcomePlanData, setOutcomePlanData] = useState<WelcomeOP | null>(null);
    const [error, setError] = useState<string | null>(null);
    const { accounts } = useMsal();
    const location = useLocation();
    const [organizationGroupId, setOrganizationGroupId] = useState<string | null>(null);
    const [outcomePlanId, setOutcomePlanId] = useState<string | null>(null);
    const [providerId, setproviderId] = useState<string | null>(null);

    const getAccessToken = async () => {
        if (accounts.length > 0) {
            try {
                const token = localStorage.getItem("token");
                return token;
            } catch (error) {
                const token = localStorage.getItem("token");
                return token;
            }
        }
        throw new Error('No accounts found');
    };

    const fetchLaunchedCases = useCallback(async () => {
        const url = apiURL.fetchLaunched + "?offset=0&limit=100&status=launched";
        // console.log(url);
        const token = await getAccessToken();
        // console.log("Token: ", token);

        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result: Welcome = await response.json();
            setData(result);

            if (result.cases.length > 0) {
              setOrganizationGroupId(result.cases[0].organizationGroupId);
              // console.log("organizationGroupId:", result.cases[0].organizationGroupId);
            }

            if (result.cases[0].outcomePlanId) {
              setOutcomePlanId(result.cases[0].outcomePlanId.id);
              // console.log("outcomePlanId:", result.cases[0].outcomePlanId.id);
            }

            if (result.cases[0].provider) {
              setproviderId(result.cases[0].provider.providerId);
              // console.log("providerId:", result.cases[0].provider.providerId);
            }            

        } catch (error) {
            setError((error as Error).message);
        }
    }, [accounts]);

    const fetchInReviewCases = useCallback(async () => {
        const url = apiURL.fetchInReview + "?offset=0&limit=100&status=in_review";
        const token = await getAccessToken();
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result: Welcome = await response.json();
            setDraft(result);
           
        } catch (error) {
            setError((error as Error).message);
        }
    }, [accounts]);

    const fetchOutcomePlans = useCallback(async (organizationGroupId: string) => {
        const url = `${apiURL.fetchOutcomePlan}${organizationGroupId}`;
       
        // console.log("Fetching Outcome Plan from URL: ", url);
    
        const token = await getAccessToken();
        try {
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': '*/*',
                    'Authorization': `Bearer ${token}`
                }
            });
    
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const result: WelcomeOP = await response.json();
            setOutcomePlanData(result);
           
        } catch (error) {
            setError((error as Error).message);
        }
    }, [accounts]);

    useEffect(() => {
        fetchLaunchedCases();
        fetchInReviewCases();
        // console.log(">>>>>", organizationGroupId);
        if (organizationGroupId) {  // Check if organizationGroupId is not null
            fetchOutcomePlans(organizationGroupId);
        }
    }, [organizationGroupId, fetchLaunchedCases, fetchInReviewCases, fetchOutcomePlans]);

    useEffect(() => {
        fetchLaunchedCases();
        fetchInReviewCases();
        if (organizationGroupId) {  // Check if organizationGroupId is not null
            fetchOutcomePlans(organizationGroupId);
        }
    }, [location.pathname, fetchLaunchedCases, fetchInReviewCases, fetchOutcomePlans]);

    const caseData: Case[] = data ? data.cases : [];
    const caseDraft: Case[] = draft ? draft.cases : [];
    const outcomePlanss: OutcomePlanData[] = outcomePlanData ? outcomePlanData.outcomePlans : [];

    // console.log("Org Grp: ", caseData);
    
    return (
        <div className="Main">
            <Header UserName={props.user} Designation={"Admin"} cases={caseData} />
            <Routes>
                <Route path="/" element={<ListDash user={user} cases={caseData} draft={caseDraft} />} />
                <Route path="/case-details/:caseID" element={<DashCaseDetails cases={caseData} />} />
                <Route path="/grid-dash/" element={<GridDash user={user} cases={caseData} draft={caseDraft} />} />
                <Route path="/list-dash/" element={<ListDash user={user} cases={caseData} draft={caseDraft} />} />
                <Route path="/DashCaseDetails/:caseID" element={<DashCaseDetails cases={caseData} />} />
                <Route path="/add-new-case/" element={<AddNewCase outcomePlanss={outcomePlanss} organizationGroupId={organizationGroupId} outcomePlanId={outcomePlanId} providerId={providerId} />} />
                <Route path="/edit-case/:caseId" element={<EditCase outcomePlanss={outcomePlanss} organizationGroupId={organizationGroupId} outcomePlanId={outcomePlanId} providerId={providerId} fetch_cases={caseData} />} />
                <Route path="/providers/" element={<Providers organizationGroupId={organizationGroupId} outcomePlanId={outcomePlanId} providerId={providerId} />} />
                <Route path="/organisers/" element={<Organisations organizationGroupId={organizationGroupId} outcomePlanId={outcomePlanId} providerId={providerId} />} />
            </Routes>
            <Footer />
        </div>
    );
}

export default MainContent;
